import Campaign from '@/models/Campaigns'

export default class CampaignFactory {
  static createFromJson(json) {
    return new Campaign(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(CampaignFactory.createFromJson(item))
    })

    return jsonData
  }
}
