<template>
  <b-row>
    <b-col cols="12" md="12" xl="4" class="mb-1">
      {{ title }}
    </b-col>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      <b-form-group>
        <v-select
          v-model="operatorSelected"
          :options="operatorOptions"
          placeholder="Operator"
        />
      </b-form-group>
    </b-col>
    <b-col v-if="operatorSelected" cols="12" md="6" xl="4" class="mb-1">
      <b-form-group>
        <b-form-input
          v-model="inputValue"
          placeholder="Value"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        operator: '',
        value: '',
      }),
    },
    title: {
      type: String,
      default: '',
    },
    operatorOptions: {
      type: Array,
      default: () => ['Contain', 'Equal'],
    },
  },
  computed: {
    operatorSelected: {
      get() {
        return this.value.operator
      },
      set(operator) {
        this.$emit('input', {
          operator: operator || '',
          value: operator ? this.inputValue : '',
        })
      },
    },
    inputValue: {
      get() {
        return this.value.value
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          value,
        })
      },
    },
  },
}
</script>
