<template>
  <b-row>
    <b-col cols="12" md="12" xl="4" class="mb-1">
      {{ title }}
    </b-col>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      <b-input-group class="mb-1">
        <b-form-input
          id="start-date"
          v-model="selectedStartDate"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
          show-decade-nav
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="selectedStartDate"
            show-decade-nav
            button-only
            right
            locale="en-US"
            aria-controls="start-date"
          />
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      <b-input-group class="mb-1">
        <b-form-input
          id="end-date"
          v-model="selectedEndDate"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
          show-decade-nav
        />
        <b-input-group-append>
          <b-form-datepicker
            v-model="selectedEndDate"
            show-decade-nav
            button-only
            right
            locale="en-US"
            aria-controls="end-date"
          />
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        from: '',
        to: '',
      }),
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedStartDate: {
      get() {
        return this.value.from
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          from: value,
        })
      },
    },
    selectedEndDate: {
      get() {
        return this.value.to
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          to: value,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.b-form-datepicker {
  ::v-deep button {
    padding: 6px 12px;
  }
}
</style>
