<template>
  <b-row>
    <b-col cols="12" md="12" xl="4" class="mb-1">
      {{ title }}
    </b-col>
    <b-col cols="12" md="6" xl="4" class="mb-1">
      <b-form-group>
        <v-select
          v-model="formValue"
          :options="options"
          label="text"
          :reduce="option => option.value"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value.value
      },
      set(value) {
        this.$emit('input', { value: value || '' })
      },
    },
  },
}
</script>
