/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Campaign {
  constructor(item = {}) {
    return {
      id: item.id || item.campaign_id || '',
      name: item.name || '',
      period: item.duration || '',
      brief_date: item.brief_date ? moment(item.brief_date).format(DATE_FORMAT) : '',
      actual_campaign_start_date: item.actual_campaign_start_date ? moment(item.actual_campaign_start_date).format(DATE_FORMAT) : '',
      actual_campaign_end_date: item.actual_campaign_end_date ? moment(item.actual_campaign_end_date).format(DATE_FORMAT) : '',
      paused_date: item.paused_date ? moment(item.paused_date).format(DATE_FORMAT) : '',
      unpaused_date: item.unpaused_date ? moment(item.unpaused_date).format(DATE_FORMAT) : '',
      day_left: item.day_left || '',
      kpi: item.kpi || '',
      per_cycle_budget: item.per_cycle_budget || '',
      alpha_campaign_id: item.alpha_campaign_id || '',
      google_account_id: item.google_account_id || [],
      facebook_account_id: item.facebook_account_id || [],
      tiktok_account_id: item.tiktok_account_id || [],
      pricing_model: item.pricing_model || '',
      // bonus_discount: item.bonus_discount || '0',
      // landing_page_type: item.landing_page_type || '',
      // landing_page_language: item.landing_page_language || '',
      // uniqued_selling_point: item.uniqued_selling_point || '',
      // service_focus: item.service_focus || '',
      // keyword_ideas: item.keyword_ideas || '',
      // did_phone: item.did_phone || '',
      // creator: item.creator || '',
      // remark: item.remark || '',
      industry: item.industry || '',
      // media_plan: item.media_plan || '',
      // line_notifications: item.line_notifications || '',
      // line_notifications_language: item.line_notifications_language || '',
      // line_notifications_email: item.line_notifications ? item.line_notifications.map(element => element.email) : [],
      // viewers: item.viewers || [],
      // brief_commit_artwork: item.brief_commit_artwork || '',
      // brief_pieces_sets: item.brief_pieces_sets || '',
      // client_folder_google_drive: item.client_folder_google_drive || '',
      // additional_requirement_url: item.additional_requirement_url || '',
      // facebook_page_access: item.facebook_page_access || '',
      // auto_status_flag: item.auto_status_flag || '',
      // content_from_am: item.content_from_am || '',
      // fields_chat_lead: item.fields_chat_lead || '',
      // chatbot_launch_date: item.chatbot_launch_date ? moment(item.chatbot_launch_date).format(DATE_FORMAT) : '',
      // flow_type: item.flow_type || '',
      // brief_lead_data_requirement: item.brief_lead_data_requirement || '',
      // brief_product_service_branch: item.brief_product_service_branch || '',
      // brief_faq: item.brief_faq || '',
      // brief_promotion_offer: item.brief_promotion_offer || '',
      account_name: item.account_name || '',
      campaign_status: item.campaign_status || '',
      opportunity: item.opportunity || '',
      objectives: item.objectives || '',
      country_code: item.country_code || '',
      // additional_information: item.additional_information || '',
      // demo_campaign: item.demo_campaign || false,
      is_pending_approval: item.is_pending_approval || false,
      is_creative_campaign: item.is_creative_campaign || false,
      is_seo_campaign: item.is_seo_campaign || false,
      have_media_products: item.have_media_products || false,
    }
  }
}
