<template>
  <b-modal
    id="modal-campaign-filter"
    scrollable
    title="Filter"
    size="lg"
  >
    <b-form @submit.stop.prevent>
      <template v-for="filter in filterList">
        <component
          :is="filter.component"
          :key="filter.key"
          v-model="filter[filter.key]"
          v-bind="{
            ...filter
          }"
        />
      </template>
    </b-form>
    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <HeroButtonAction
            variant="outline-secondary"
            type="button"
            class="w-auto mr-1"
            @click="$bvModal.hide('modal-campaign-filter')"
          >
            Cancel
          </HeroButtonAction>
          <HeroButtonAction
            variant="outline-secondary"
            type="button"
            class="w-auto mt-0"
            @click="clearFilter"
          >
            Clear
          </HeroButtonAction>
        </div>
        <HeroButtonAction
          variant="primary"
          type="button"
          class="w-auto"
          @click="save"
        >
          Save
        </HeroButtonAction>
      </div>
    </template>
  </b-modal>
</template>
<script>
import TextFieldFilter from '@/views/heroai/opportunities/view/components/TextFieldFilter.vue'
import DateFilter from '@/views/heroai/opportunities/view/components/DateFilter.vue'
import SelectFilter from '@/views/heroai/opportunities/view/components/SelectFilter.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
    SelectFilter,
    TextFieldFilter,
    DateFilter,
  },
  props: {
    selectFilterDropdown: {
      type: Object,
      default: () => ({
        isLoading: false,
        campaignStatusOptions: [],
        pricingModelOptions: [],
        industryOptions: [],
        objectiveOptions: [],
        countryOptions: [],
      }),
    },
  },
  data() {
    return {
      filterList: [
        {
          key: 'name',
          title: 'Campaign Name',
          name: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'duration',
          title: 'Duration',
          duration: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'opportunity_name',
          title: 'Opportunity Name',
          opportunity_name: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'kpi',
          title: 'KPI',
          kpi: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'alpha_campaign_id',
          title: 'HeroAI Campaign ID',
          alpha_campaign_id: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'google_account_id',
          title: 'Google Account ID',
          operatorOptions: ['Contain'],
          google_account_id: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'facebook_account_id',
          title: 'Facebook Account ID',
          operatorOptions: ['Contain'],
          facebook_account_id: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'tiktok_account_id',
          title: 'TikTok Account ID',
          operatorOptions: ['Contain'],
          tiktok_account_id: {
            operator: '',
            value: '',
          },
          component: TextFieldFilter,
        },
        {
          key: 'brief_date',
          title: 'Brief Date',
          brief_date: {
            from: '',
            to: '',
          },
          component: DateFilter,
        },
        {
          key: 'start_date',
          title: 'Start Date',
          start_date: {
            from: '',
            to: '',
          },
          component: DateFilter,
        },
        {
          key: 'end_date',
          title: 'End Date',
          end_date: {
            from: '',
            to: '',
          },
          component: DateFilter,
        },
        {
          key: 'paused_date',
          title: 'Campaign Pause Date',
          paused_date: {
            from: '',
            to: '',
          },
          component: DateFilter,
        },
        {
          key: 'restart_date',
          title: 'Campaign Restart Date',
          restart_date: {
            from: '',
            to: '',
          },
          component: DateFilter,
        },
        {
          key: 'status',
          title: 'Status',
          options: this.selectFilterDropdown.campaignStatusOptions,
          status: {
            value: '',
          },
          component: SelectFilter,
        },
        {
          key: 'pricing_model',
          title: 'Pricing Model',
          options: this.selectFilterDropdown.pricingModelOptions,
          pricing_model: {
            value: '',
          },
          component: SelectFilter,
        },
        {
          key: 'industry',
          title: 'Industry',
          options: this.selectFilterDropdown.industryOptions,
          industry: {
            value: '',
          },
          component: SelectFilter,
        },
        {
          key: 'objectives',
          title: 'Objectives',
          options: this.selectFilterDropdown.objectiveOptions,
          objectives: {
            value: '',
          },
          component: SelectFilter,
        },
        {
          key: 'country',
          title: 'Country',
          options: this.selectFilterDropdown.countryOptions,
          country: {
            value: '',
          },
          component: SelectFilter,
        },
      ],
    }
  },
  methods: {
    clearFilter() {
      this.filterList = this.filterList.map(filter => ({
        ...filter,
        [filter.key]: Object.keys(filter[filter.key]).reduce((obj, item) => ({
          ...obj,
          [item]: '',
        }), {}),
      }))
    },

    save() {
      const filterData = this.filterList.reduce((obj, item) => ({
        ...obj,
        [item.key]: item[item.key],
      }), {})

      this.$emit('filter', filterData)
      this.$bvModal.hide('modal-campaign-filter')
    },
  },
}
</script>
