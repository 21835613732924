<template>
  <b-form-group
    :label-cols-sm="column"
  >
    <b-form-checkbox
      :id="id"
      v-model="formValue"
      :disabled="disabled"
      :indeterminate="indeterminate"
      @change="$emit('change', $event)"
    >
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }}
      </span>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    // Got value from v-model
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
