import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import AxiosConfig from '@/services/AxiosConfigs'
// eslint-disable-next-line import/no-cycle
import ErrorHandler from '@/libs/ErrorHandler'

let token = localStorage.getItem('sanctumToken')
if (store && store.getters['heroAiAuthentications/sanctumToken']) {
  token = store.getters['heroAiAuthentications/sanctumToken']
}
let axiosConfig = AxiosConfig.getJsonWithToken(token)

class Account {
  static async get(url, qparam, isUseErrorHandler = true) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'get',
          headers: axiosConfig.headers,
          params: qparam,
        },
      )
      return response.data
    } catch (error) {
      if (isUseErrorHandler) {
        return new ErrorHandler(error)
      }
      return Promise.reject(error)
    }
  }

  static async post(url, qparam, data) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'post',
          headers: axiosConfig.headers,
          params: qparam,
          data,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }

  static async patch(url, qparam, data) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'patch',
          headers: axiosConfig.headers,
          params: qparam,
          data,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }

  static async delete(url) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'delete',
          headers: axiosConfig.headers,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  }

  static async setAuthHeader() {
    token = store.getters['heroAiAuthentications/sanctumToken']
    axiosConfig = AxiosConfig.getJsonWithToken(token)
  }
}

export default Account
